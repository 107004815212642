@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

$border-color: rgba(34,36,38,.5);
$border: .5px solid $border-color;

.table {
  color: $clever-dgray;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  @include mixins.opacityTransition(0, 1);
  overflow: hidden;
  display: block;

  > .tableRow {
    // header row
    position: relative;
    z-index: 1;
    font-weight: 700;
  }
}

.tableRow {
  display: block;
  position: absolute;
  border-bottom: $border;
  z-index: 0;
}

.tableCell {
  display: inline-block;
  padding: 2px;
  vertical-align: middle;

  &:not(:first-of-type) {
    padding-left: 4px;
  }
}

.columnDividerContainer {
  width: calc(100% - 10px);
  position: relative;

  > span {
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
    cursor: col-resize;
    z-index: 2;
    transition: color .25s ease;
    background-color: #d3d3d3;

    &:hover {
      background-color: #c5c5c5;
    }
  }
}