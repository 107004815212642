@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";
@use "../../../../../styles/zIndex.module";

.mapBlockContainer {
  position: relative;

  .map {
    height: 30rem;
    width: 100%;
    transition: all 250ms ease;
    position: relative;
  }
}

.reorderBlocks {
  button {
    color: white;

    &:hover {
      color: $lightgray;
    }
  }
}

.editDropDownMap {
  position: unset !important;
  width: unset !important;
  > div {
    > button {
      color: white !important;
      > span {
        height: 50px !important;
      }
    }
  }
}

.organizationTitle {
  background: #343430;
  color: $background-color;
  padding: 1rem 0 1rem 0;
  line-height: 3rem;
  text-align: center;
  position: relative;
  z-index: 10;
  h3 {
    font-weight: 700;
  }
  @media(max-width: $break-point-sm) {
    z-index: zIndex.$z-index-map-terrace-title;
  }

  h3 {
    display: inline-block;
  }

  button {
    font-size: 1.3rem;
    margin: 0 2rem;
  }

  .editButton {
    padding-top: 15px;
  }

  > div {
    display: inline-block;

    &:last-of-type {
      min-width: 100px;
    }

    button {
      margin: 0;
    }
  }
}

.dropDownContainer {
  color: black;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  ul {
    li {
      button {
        margin: 0;
      }
    }
  }
}

.locationItemTransitionWrapper {
  @include mixins.translateTransition(translate(-5%, -50%), translate(0, -50%));
  @include mixins.opacityTransition(0, 1);

  @media(max-width: $break-point-sm) {
    @include mixins.translateTransition(translate(-5%, -50%), translate(-50%, -50%));
  }
}

.mapInsert {
  position: absolute;
  top: 50%;
  left: 5%;
  z-index: 700;

  @media(max-width: $break-point-sm) {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  transition: opacity .5s ease, transform .25s ease;
  opacity: 0;
}