@use "../../../../../styles/variables.module" as *;

.loadingItem {
  margin-inline: 10px;
  text-align: center;
  background-color: white;
  padding-top: 20px;
  > span {
    font-size: 10rem;
    color: lightgrey;
  }
}

.container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  color: $black;
  justify-content: center;
  > div {
    flex: 0 0 calc(25% - 20px);

    &:nth-child(n+5) {
      display: none;
    }

    @media (max-width: $break-point-md) {
      flex: 0 0 calc(33.33333% - 20px);
      &:nth-child(n+4) {
        display: none;
      }
    }

    @media (max-width: 720px) {
      flex: 0 0 calc(50% - 20px);
      &:nth-child(n+3) {
        display: none;
      }
    }

    @media (max-width: $break-point-sm) {
      flex: 0 0 calc(100% - 20px);
      &:nth-child(n+2) {
        display: none;
      }
    }
  }
}

.shopInfoMessage {
  text-align: center;
  font-size: 18px;
  margin: 20px 0 20px 0;
}

.blockTitleContainer {
  a:hover {
    > div {
      transform: translateX(-100%);
    }
  }
  &Mobile {
    display: none;
    @media (max-width: $break-point-sm) {
      display: block;
      text-align: center;
      margin-top: 10px;
      a:hover {
        > div {
          transform: translateX(-100%);
        }
      }
    }
  }
}
