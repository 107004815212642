@use "../../../styles/mixins";

// TODO: this can be removed when we refactor the header elements on classroom, group, and section pages to
// be rendered in React.  Right now, we render it in PHP and the "static" SCSS pulls in this mixin to use the same
// code.
@mixin define-element-spacer() {
    height: 1rem;
    @include mixins.minScreenMd {
        height: 2rem;
    }
}

.elementSpacer {
    @include define-element-spacer();
}
