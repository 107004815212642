@use "../../../../styles/variables.module";

.container {
    position: relative;
    overflow: hidden;
}

.video {
    display: block;
    position: absolute;

}
.isHero {
    @media(min-width: variables.$break-point-sm) {
        height: 100vh !important;
        width: 100vw !important;
    }
    @media(max-width: variables.$break-point-sm) {
        height: 75vw;
        width: 100vw;
        aspect-ratio: 4/3;
    }
}

.cover {
    composes: video;

    overflow: hidden;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    height: auto;
    width: auto;
}

.contain {
    composes: video;
    overflow: hidden;
    background: #333;
    height: 100%;
    width: 100%;
}
