@use "../../../../../styles/mixins";
@use "../../../../../styles/variables.module" as *;
@use "../../styles/TerraceDescriptionStyles.module";

.viewBtn:hover {
  background-color: rgb(254, 182, 38);
  color: white;
}

.viewBtn {
  text-transform: uppercase;
  padding: 10px 50px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: rgb(254, 182, 38);
  border: 2px solid rgb(254, 182, 38);
  display: inline-block;
}

.messageBlock {
  position: relative;
  z-index: 5;
  @include TerraceDescriptionStyles.terraceDescription;

  > button {
    display: inline-block;
    background: var(--blocks);
    color: var(--blocksTextColor);
    border-radius: 50%;
    padding: 8px;
    margin-left: 5px;
  }
  > div {
    display: inline-block;
    max-width: 100%;
  }

  &Editable {
    background-color: rgba(0,0,0,.05);
    cursor: pointer;
  }
}

.messageContainer {
  position: relative;
}

.editor {
  text-align: left;
  @include TerraceDescriptionStyles.terraceDescription;
}
