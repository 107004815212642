@use "../../../styles/variables.module" as *;
@use "../../blocks/TerracedGridTheme/styles/TerraceDescriptionStyles.module";

.callToActionContainer {
  background-color: var(--primaryNavBackground);
  color: var(--primaryNavBackgroundTextColor);

  position: absolute;
  left: 130px;

  @media (min-width: $break-point-sm) {
    max-width: 400px;
    max-height: 250px;
    opacity: 95%;
    border-radius: 7px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
    bottom: 82px;
    min-width: 223px;
  }
  &HomePage{
    @media (min-width: $break-point-sm) {
      bottom: 150px;
    }
  }


  @media (max-width: $break-point-sm) {
    left: 0;
    position: relative;
  }

  &Preview {
    left: 50px;
    bottom: 10%;
    min-width: unset;

    @media (max-width: $break-point-sm) {
      left: 50px;
    }
  }

  &PreviewMobile {
    left: 0;
    z-index: 9;
    top: 304px;
    bottom: 200px;
    &Video {
      top: -304px;
    }
  }
}

.callToActionBody {
  position: relative;
  padding: 10px;

  > span {
    display: block;
    font-size: 22px;
    font-weight: $terrace-subheading-font-weight;
    margin: 0 10px 10px;
    text-align: left;
  }

  > p {
    font-size: 16px;
    line-height: 1.5;
    margin: 4px 10px 20px;
    text-align: left;
  }

  &Preview {
    padding: 10px;
    max-width: 230px;
    max-height: 120px;
    width: auto;
    text-align: left;

    > span {
      font-size: 11px;
      margin: 0 0 2px;
    }

    > p {
      font-size: 7px;
      margin: 0 0 2px;
    }

    &Desktop {
      @media (max-width: $break-point-sm) {
        padding: 5px;
        min-width: 50px;
        max-width: 75px;
        max-height: 40px;

        > span {
          position: absolute;
          font-size: 4px;
          margin: 0;
        }

        > p {
          font-size: 2px;
          margin: 0;
        }
      }
    }

    &Mobile {
      max-width: 300px;
      width: 300px;
      left: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }

  @media (max-width: $break-point-sm) {
    width: 100vw;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.callToActionLink {
  @include TerraceDescriptionStyles.callToActionButton;
  font-weight: 600;
  background: var(--elements);
  color: var(--elementsTextColorNoBlocks);
  border: 2px solid white;
  transition: transform 0.3s;

  text-align: center;

  @media (max-width: $break-point-sm) {
    min-width: 90vw;
  }

  &:hover {
    transform: scale(1.05);
    color: var(--elementsTextColorNoBlocks);
  }

  &Preview {
    font-size: 0.6rem;

    &:hover {
      transform: scale(1.2);
    }

    &Desktop {
      @media (max-width: $break-point-sm) {
        min-width: 25px;
        font-size: 0.2rem;
        padding: 0.1rem;
      }
    }
    &Mobile {
      float: none;
      margin: 0 auto;
      display: inline-block;
      justify-content: center;
      align-items: center;
      width: auto;
      min-width: 90%;
    }
  }

  @media (max-width: $break-point-sm) {
    float: none;
    margin: 0 auto;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: auto;
    &:hover {
      margin: auto;
    }
  }
}

.buttonPreviewContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.closeButton {
  padding: 4px;
  z-index: 801;
  position: relative;
  color: var(--primaryNavBackgroundTextColor);
  cursor: pointer;
  font-size: 14px;
  float: right;

  @media (max-width: $break-point-sm) {
    display: none;
  }


  &PreviewMobile {
      display: none;
    }
}

.hidden {
  display: none;
}

.callToActionMinimized {
  background-color: var(--primaryNavBackground);
  color: var(--primaryNavBackgroundTextColor);
  opacity: 95%;
  border-radius: 7px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 130px;
  max-width: 400px;
  max-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $break-point-md) {
    bottom: 16%;
  }

  > button > span {
    font-size: 22px;
    font-weight: $terrace-subheading-font-weight;
  }
}
