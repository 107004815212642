/**************************Tooltip starts*************************/
.tooltipMenu {
    min-width: 250px;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 5px 5px 20px grey;
    //visibility: hidden;
    display: none;
    position: absolute;
    z-index: 1;
    margin-top: 1.5rem;
    padding: 2rem 0.5rem;
    left:50%;
    transform:translate(-50%, 0);

    &.visible {
        //visibility: visible;
        display: block;
    }

    .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    ul {
        margin: 0;
        padding: 1rem 0 0 0;

        li {
            text-align: left;
            padding: 1rem 1.5rem;
            font-size: 1.5rem;
            width: 100%;
            list-style: none;

            a {
                color: initial;
                text-decoration-line: underline;

                &:hover, &:focus {
                    font-weight: bold;
                }
            }
        }
    }

    &.bottomSide::after {
        bottom: 100%;
        left: 45%;
        border-color: transparent transparent white transparent;
    }

    &::after {
        content: " ";
        position: absolute;
        border-width: 10px;
        border-style: solid;



        &.leftSide {
            left: 100%;
            top: 5%;
            border-color: transparent transparent transparent white;
        }

        &.rightSide {
            right: 100%;
            top: 5%;
            border-color: transparent white transparent transparent;
        }

        &.topSide {
            right: 45%;
            top: 100%;
            border-color: white transparent transparent transparent;
        }
    }
}

.bottomSide {
    top: 100%;
}

.topSide {
    bottom: 100%;
}

.leftSide {
    right: 70%;
    top: 15%
}

.rightSide {
    left: 70%;
    top: 15%;
}

