@use "../../../../styles/variables.module" as *;
@use "../../../../styles/mixins";

.container {
  padding: 0;
  margin-bottom: 0;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .errorStatus {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
    border-radius: 100%;
    z-index: 1000;
  }
  .tooltip {
    @include mixins.tooltip();
    &::after {
      top: 0 !important;
      left: 50%;
      transform: translate(-50%, calc(-100% - 10px)) !important;
      margin-left: 0 !important;
      width: 150px !important;
      white-space: pre-wrap !important;
      height: auto ;
    }
    &::before {
      left: 50% !important;
      top: -10px !important;
      transform: translate(-50%, -50%) rotate(45deg) !important;
    }
  }

  .channelButton {
    color: $black;
    flex-basis: 100px;
    list-style: none;
    border-radius: 10px;
    border: 1px solid $black;
    position: relative;
    height: 105px;


    button:first-of-type {
      width: 100%;
      height: 100%;
      padding: 5px;
      transition: none;

      span {
        font-size: 40px;
        display: block;
      }
    }


    button:nth-of-type(2) { // facebook info button
      position: absolute;
      left: 3px;
      top: 3px;
      color: var(--blocks);

      span {
        font-size: 18px;
        transition: font-size .25s ease;
      }

      span:before {
        background: white;
        border-radius: 100%;
        border: 1px solid white;
      }

      &:hover span {
        font-size: 20px;
      }
    }

    > span { // sent/errored indicator
      font-size: 20px;
      position: absolute;
      right: 3px;
      bottom: 3px;
    }

    &:hover:not(&Disabled), &Selected {
      color: var(--blocksTextColor);
      background: var(--blocks);
    }

    &Disabled {
      opacity: .5;
    }


  }

  .manageChannelsButton {
    width: 50px;
    height: 50px;
    color: $black;
    font-size: 2rem;
  }
}
