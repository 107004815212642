@use "../../../../../styles/variables.module" as *;

.mapInsert {
  height: auto;
  padding: 20px;
  background-color: var(--primaryNavBackground);
  font-size: 1rem;
  border-radius: 5px;

  > div:nth-of-type(1) { // logo container
    img {
      max-height: 60px;
      max-width: 100%;
    }
    text-align: center;
  }

  div {
    a {
      color: inherit;
    }
  }

  > div {
    padding-bottom: 10px;
  }

  > button {
    position:absolute;
    right: 10px;
    top: 10px;
    color: var(--primaryNavBackgroundTextColor);
  }

  .insertSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border: 1px solid lightgray;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 5px;
    background: #FAFAF7;

    address {
      margin: 0;
    }
    > span {
      padding-right: 15px;
      color: var(--blocks);
    }
    > div, address {
      display: inline-block;
      vertical-align: top;
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: $break-point-sm) {
  .mapInsert {
    max-width: 70%;
  }
}
