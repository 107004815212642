@use "../../../../styles/variables.module";

.searchBox {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 15px;
}
.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.sb-with-background-image) {
  .filterContainer {
    width: fit-content;
    background-color: white;
    padding-top: 20px;
    border-radius: 15px;
    display: inline-block;
  }

  .filterContainerWrapper {
    text-align: center;
  }
}