@use "../../../../../node_modules/react-leaflet-markercluster/dist/styles.min.css";

.leafletMapContainer {
  height: 100%;
  width: 100%;
  z-index: 1;

  &Grayscale {
    > div > div > div > div > img { // select only the tile images
      filter: grayscale(.7);
    }
  }
}

.cluster {
  background-color: var(--primaryNavBackground);
  height: 64px;
  width: 64px;
  line-height: 64;
  font-size: 1.2rem;
  text-align: center;
  color: var(--primaryNavBackgroundTextColor);
  position: absolute;
  border-radius: 100%;

  span {
    position: absolute;
    line-height: 64px;
    width: 64px;
    display: block;
    text-align: center;
  }
}

.leaflet-marker-icon .custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin {
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  border: 18px solid black;
  width: 8px;
  height: 8px;
  right: -2px;
  top:-5px;
}

.pin::after {
  position: absolute;
  z-index: 10;
  content: '';
  width: 0px;
  height: 0px;
  border: 15px solid transparent;
  border-top: 25px solid black;
  left: -15px;
  bottom: -48px;
}
