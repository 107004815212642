@use "../../../styles/variables.module" as *;

.container {
}

.popup {
  position: absolute;
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: $box-shadow;
  z-index: 1;

  ul {
    overflow-y: scroll;
    height: 200px;
  }
}
