@use "../../../../../styles/variables.module" as *;
@use "../../../../../styles/mixins";

.unsliderContainer {
  .transitionEnter {
    opacity: 0 !important;
    z-index: 1 !important;
  }
  .transitionEnterActive {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionEnterDone {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionExit {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionExitActive {
    opacity: 0 !important;
    z-index: 1 !important;
  }
  .transitionExitDone {
    opacity: 0 !important;
    z-index: 0 !important;
  }
}

.blockScroller {
  > div > div > div > a, > a {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
  }
}

.multiItemHeader {
  margin-top: 1rem;

  h4 {
    display: inline-block;
  }
  button {
    display: inline-block;
  }
  > span {
    margin-top: 4px;
    color: #696969;
    float: right;
  }
}

.messageList {
  list-style: none;
  .messageItem {
    position: relative;
    padding-bottom: 15px;
    textarea {
      resize: none;
    }
    button {
      border: none;
      background: none;
      [class*="fa-"] {
        font-size: 1.5em;
      }
    }
    h4,.h4 {
      font-size: 2rem;
      margin-left: 15px;
      margin-bottom: 25px;
      display: inline-block;
    }
  }
  .messageItemDivider {
    border-bottom: 2px solid #eeeeee;
  }
}

.markdownEditor {
  background: white;
}

.button {
  @include mixins.buttonColor($admin-blue-dark, white);
}
.mobilePositionControls {
  position: absolute;
}