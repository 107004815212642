@use "variables.module" as *;

.fontColorTwitter {
  color: $twitter-color;
}
.fontColorFacebook {
  color: $facebook-color;
}
.fontColorLinkedin {
  color: $linkedin-color;
}
.fontColorInstagram {
  color: $instagram-color;
}
.fontColorOffice365 {
  color: $office365-color;
}
.fontColorGoogle {
  color: $google-color;
}

.bgColorTwitter {
  background-color: $twitter-color;
}
.bgColorFacebook {
  background-color: $facebook-color;
}
.bgColorLinkedin {
  background-color: $linkedin-color;
}
.bgColorInstagram {
  background-color: $instagram-color;
}
.bgColorOffice365 {
  background-color: $office365-color;
}
.bgColorGoogle {
  background-color: $google-color;
}
