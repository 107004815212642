.markdown {
  li:only-of-type, p {
    white-space: pre-line; // this preserves \n newline values
  }

  h3 {
    margin: 0 0 14px;
  }

  p {
    margin: 0 0 10px;
  }

  a {
    text-decoration: underline !important;
  }

  del {
    color: inherit;
  }

  blockquote {
    border-left: 2px solid currentColor;
    font-size: 14px !important;
    padding: 0 0 0 10px !important;
    color: inherit;
    font-style: italic;
  }
}
