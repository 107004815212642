@use '../../../styles/variables.module' as *;

.orgLogo {
  height: $hero-logo-height;
  font-size: 2.5rem;

  &Disabled {
    pointer-events: none;
  }

  &Upload {
    label {
      background-color: lightgray;
      color: black;
      padding: 1rem;
      font-size: 1.5rem;
      border: 4px dashed white;
      height: auto !important;

      &:hover {
        background-color: darken(lightgray, 10%);
      }
    }

    &Loading {
      label {
        pointer-events: none;
      }
    }
  }

  input { //when input is inside of .orgLogo it should be hidden, just not when input IS .orgLogo
    display: none;
    height: inherit;
  }

  img {
    height: inherit;
    max-width: 95%;
    object-fit: contain;
  }

  label {
    cursor: pointer;
    height: inherit;
  }

  //label {
  //  height: $hero-logo-height;
  //  width: auto;
  //  margin: 0 auto;
  //  top: -$hero-logo-top;
  //  right: 0;
  //  left: 0;
  //  position: absolute;
  //  cursor: pointer;
  //}
}

.uploadContainer {
  display: inline-block;
  background-color: white;
  padding: .5rem;
  border-radius: 5px;

  svg {
    vertical-align: bottom;
  }
}
