@use "../../../styles/mixins";
@use "../../../styles/variables.module" as *;
.container {
  .transitionEnter {
    opacity: 0 !important;
    z-index: 1 !important;
  }
  .transitionEnterActive {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionEnterDone {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionExit {
    opacity: 1 !important;
    z-index: 1 !important;
  }
  .transitionExitActive {
    opacity: 0 !important;
    z-index: 1 !important;
  }
  .transitionExitDone {
    opacity: 0 !important;
    z-index: 0 !important;
  }
  @media(max-width: $break-point-sm) {
    bottom: -50px;
  }
}

.slideContainer {
  cursor: default;
}

.slide {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: opacity 1s ease;
  z-index: 0;
  opacity: 0;

  &:first-of-type {
    z-index: 1;
    opacity: 1;
  }
}

.controls {
  @media (min-width: $break-point-sm) {
    position: absolute;
  }
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .7);
  text-align: center;
  vertical-align: middle;
  padding: 7.5px 30px;


}

.indicator {
  display: inline-block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  margin: 0px 2px 3px 2px;
  cursor: pointer;
  transition: background-color .5s ease;
  z-index: 1;
  &.active {
    &Hero{
      background: grey;
      @media (max-width: $break-point-sm) {
        background: white;
      }
    }
    background: white;

  }
}

.indicator::before,
.indicator::after {
  --scale: 0;
  --arrow-size: 20px;
  --tooltip-color: green;

  position: absolute;
  top: -.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}

.indicator::before {
  --translate-y: calc(-100% - var(--arrow-size));

  content: attr(data-tooltip);
  color: white;
  padding: .5rem;
  border-radius: .3rem;
  text-align: center;
  width: max-content;
  max-width: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.indicator:focus-visible::before,
.indicator:focus-visible::after {
  --scale: 1;
}

.indicator::after {
  --translate-y: calc(-1 * var(--arrow-size));

  content: '';
  border: var(--arrow-size) solid transparent;
  border-top-color: rgba(0, 0, 0, 0.7);
  transform-origin: top center;
}

.pause {
  position: absolute;
  bottom: 9px;
  left: 9px;
  color: white;
}
