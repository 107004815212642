@use "../../../../../styles/mixins";

.loadingContainer {
  display: block;
  max-width: 900px;
  width: 100%;
  height: 160px;
  margin: 40px auto;
  position: relative;

  @include mixins.loadingAnimation();
}

.backgroundMasker {
  background: #fff;
  position: absolute;
}

.dateLeft {
  composes: backgroundMasker;
  left: 0;
  height: 100%;
  top: 0;
  width: 20px;
}

.dateTop {
  composes: backgroundMasker;
  top: 0;
  left: 0;
  height: 30px;
  width: 20px;
}

.dateRight {
  composes: backgroundMasker;
  left: 220px;
  height: 100%;
  top: 0;
  width: 0px;
}

.dateBottom {
  composes: backgroundMasker;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 0px;
}

.titleRight {
  composes: backgroundMasker;
  right: 0;
  top: 0;
  width: 30%;
  height: 40px;
}

.contentRight {
  composes: backgroundMasker;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
}

.titleBottom {
  composes: backgroundMasker;
  width: 100%;
  height: 15px;
  top: 30px;
  left: 0px;
}

.locationRight {
  composes: backgroundMasker;
  top: 40px;
  height: 50px;
  width: 40px;
  left: 50%;
}

.contentTop {
  composes: backgroundMasker;
  right: 0;
  height: 15px;
  top: 75px;
  width: 100%;
}

.contentMiddle {
  composes: backgroundMasker;
  right: 0;
  height: 15px;
  top: 110px;
  width: 100%;
}

.contentBottom {
  composes: backgroundMasker;
  right: 0;
  height: 20px;
  top: 145px;
  width: 100%;
}
