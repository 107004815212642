.inputGroup {
  padding: 10px;
  justify-content: space-around;

  > label {
    vertical-align: top;
    font-size: 1.5rem;
    font-weight: 600;
    color: #555;
  }
  > small {
    display: block;
  }
}
.requiredStyling {
  width: max-content;
}
