@use "../../../styles/variables.module" as *;

.container {
  position: relative;
  padding: 2px;

  &Hover {
    &:hover {
      cursor: text;
      border: 2px solid $lightgray;

      &:after {
        content: "\f164";
        font-family: Flaticon;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $lightgray;
        padding: 2px 4px;
        font-size: 14px;
      }
    }
  }
}
