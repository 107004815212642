@use "../../../../../styles/variables.module" as *;

.cleverSliderContainer {
  > button {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }
}

.childSlideContainerMobile{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

:global(.sb-with-background-image) {
  .container .slideItem {
    @media (min-width: $break-point-sm) {
      background-color: white;
    }
  }
  .scrollArrow {
    span::before {
      background-color: white;
      border-radius: 50px;
    }
  }
}

.container {
  font-size: 1.4rem;

  .slideItem {
    padding: 1rem 2rem;
    border-radius: 20px;
    display: inline-block;
    box-shadow: 2px 2px 5px 2px lightgray;
    margin-left: 5px;
    margin-top: 5px;
    max-height: 295px;
    overflow-y: auto;

    // spread into max 2 columns, but only if screen is large enough to accommodate
    @media(min-width: 950px) {
      &[data-columns="2"] > ul > li > ul {
        columns: 2;
      }
    }

    // these all apply to links on their own (not under a folder)
    > ul > li > span[class*="fa-"] {
      width: 2rem;
      font-size: 22px;
      margin-right: 1rem;

      @media(
        max-width: $break-point-sm
      ) {
        font-size: 20px;

      }

      & + span {
        width: calc(100% - 2rem - 1rem);
        font-weight: $terrace-subheading-font-weight-two;
        vertical-align: middle;
        a {
          color: $black;
          @media(max-width: $break-point-sm) {
            font-size: $terrace-body-font-size;
          }
        }
      }
    }
  }
}
