@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.editable {
  display: inline-block;
  min-width: 50px;
}

.tooltip {
  @include mixins.tooltip();
}

.uploading {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  cursor: wait;
  border-radius: 50%;
  position: absolute;

  > div {
    position: absolute;
    top: 30%;
    color: #FFF;
    width: 100%;
    text-align: center;
    pointer-events: none;
  }
}

.editPopup {
  background: white;
  border-radius: 10px;
  width: auto;
  max-width: 100vw;
  text-align: left;
  font-size: initial;

  > button {
    position: absolute;
    right: 2px;
    top: 0;
  }

  label {
    margin-right: 10px;
  }

  > div {
    padding: 9px 14px;

    &:last-of-type {
      // buttons container
      margin-top: 5px;
    }
  }

  h3 {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
  }

  > div button {
    padding: 5px;
    color: white;
    height: 30px;
    margin-right: 5px;
    border: 1px solid $border-color;
    border-radius: 5px;
    box-shadow: $box-shadow;

    span {
      vertical-align: text-top;
    }

    &:nth-of-type(1) {
      background-color: $admin-blue-dark;
    }

    &:nth-of-type(2) {
      background-color: $error-red;
      color: white;
    }
  }
}

.classroomButton {
  text-transform: uppercase;
  white-space: normal;
  width: 100%;
  margin: 0;
  padding: 20px 16px;
  position: relative;
  display: inline-block;
  color: #777771 !important;
  cursor: pointer;
  border: 2px solid #777771;
  border-radius: 5px;

  a {
    color: #777771 !important;
  }

  &:hover {
    text-decoration: none;
    background-color: #e6e6e6;
  }
}

.classroomButtonContainer {
  position: relative;
  width: 268px;
  float: left;
  margin: 8px;
  color: #777771;
  font-weight: bold;
  font-size: 1.5rem;
}

.spanCreate {
  font-size: 15px;
  text-align: center;
  width: 100%;
}

.spanCreateNew {
  font-size: 15px;
  font-weight: normal;
  float: left;
  text-align: center;
  width: 96%;
}

.linkToSite {
  position: absolute;
  top: -2px;
  right: 3px;
  border-bottom: none;
  cursor: pointer;
  color: #6B6B6B;
  line-height: 1.2em;
}
