.htmlBlockContainer {
  text-align: center;
  transition: transform .25s ease;

  input {
    width: 200px;
  }

  img {
    max-width: 100%;
  }

  iframe {
    max-width: 90%;
  }

  textarea {
    padding: 40px;
    box-shadow: rgba(118, 143, 255, 0.2) 0 16px 24px 0;
    width: 90%;
    min-height: 300px;
    font-family: monospace;
    font-size: 1.1rem;
  }
}

.editTitle {
  border: none;
  font-size: 2.5rem;
  color: inherit;
}

