@use "../../../styles/variables.module" as *;
@use "config";
@use "../../../styles/mixins";

$headerHeight: 50px;
$subheaderHeight: 30px;
$width: $column-width * 2.5;
$angleOffset: 50px;
$red: rgba(243, 84, 118, 0.5);

.openEmbedMobileButton {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 50px;
  background: white;
  border: 2px solid $schoolfeed-light-blue;
  border-radius: 20px;
  padding: 3px 5px;

  img {
    height: 100%;
  }

  span {
    margin-right: .5rem;
    vertical-align: middle;
    color: $schoolfeed-light-blue;
    font-size: 32px;
    float: left;
    line-height: 40px;
  }

  @media(min-width: $width) {
    display: none;
  }
}

.schoolfeed {
  position: fixed;
  right: calc(50% - (#{$width}/2));
  overflow: visible;
  width: $width;
  max-width: calc(100% - 20px);
  z-index: 900;
  display: none;

  @media(max-width: $break-point-sm) {
    right: 10px !important;
  }

  @media (min-width: $break-point-lg) {
    display: block;
  }

  @if config.$app_ctx == "schoolfeed-embed" {
    @media(max-width: $width) {
      transform: translateY($headerHeight);
    }
  }

  /* Everything below is necessary for the embed widget to look right. These are taken from _reset.scss */
  font-family: var(--fontFamily);
  color: #333;

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #eee;
  }

  p {
    margin: 0;
  }

  button {
    border: none;
    cursor: pointer;
  }

  a {
    color: #337ab7;
    text-decoration: none;
  }
}

.header {
  height: $headerHeight;
  background: var(--secondaryNavBackground);
  position: relative;
  box-shadow: 1px 1px 10px 4px rgba(72, 72, 72, 0.5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: default;

  .openButton {
    color: var(--secondaryNavBackground);
    position: absolute;
    z-index: 2;
    display: block;
    right: 10px;
    transition: all .2s ease-in-out;

    .popupArrow {
      padding-right: 5px;
      color: $schoolfeed-light-blue;
      transition: transform .25s ease;
    }

    &:hover{
      transform: scale(1.2);
    }
  }

  > svg {
    height: 100%;
    margin-left: 15px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    @media(max-width: $break-point-sm) {
      height: 90%;
    }

    > g {
      &:first-of-type {
        fill: var(--secondaryNavBackgroundTextColor);
      }
      &:last-of-type {
        fill: var(--secondaryNavBackgroundTextColor);
      }
    }

    @media(max-width: $width) {
      margin-left: 10px;
    }
  }

  .logo {
    height: 100%;
    margin-left: 5%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 5px;
    cursor: pointer;
    transition: transform .25s ease;

    &:hover{
      transform: scale(1.08);
    }
  }

  .headerRightText {
    position: absolute;
    right: 0;
    border-top-right-radius: 10px;
    width: $width * 0.75;
    font-weight: 600;
    top: 0;
    color: #337ab7 !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: default;

    &ThemeColor {
      span {
        color: var(--primaryNavBackground);
      }
      a {
        color: var(--primaryNavBackground);
      }
    }

    .allLinks {
      display: flex;
      flex-direction: row;
      margin-inline: 5%;
      width: 100%;
    }

    .schoolFeedLink {
      margin-inline: 2%;
      width: 50%;

      &:hover{
        font-weight: bold;
      }
    }

    @media(max-width: $width) {
      color: var(--primaryNavBackground);
      background: white;
      height: $headerHeight;
      width: 33%;
      clip-path: polygon($angleOffset 0, 100% 0, 100% 100%, 0 100%);
      font-size: 0;
    }

    @media(min-width: $width) {
      color: var(--secondaryNavBackground);
      background: white;
      float: right;
      height: $headerHeight;
      clip-path: polygon($angleOffset 0, 100% 0, 100% 100%, 0 100%);
      line-height: $headerHeight;
      text-align: center;
    }
  }
}

.subheader {
  height: $subheaderHeight;
  line-height: $subheaderHeight;
  padding-left: 1rem;
  font-weight: 600;

  button {
    text-decoration: underline;
    font-weight: 100;
    font-size: 1rem;
  }
}

.contentContainer {
  box-shadow: 2px 4px 6px 1px rgba(72, 72, 72, 0.5);
  background: white;

  // when mobile nav footer is visible, need to pad the bottom equal to its height so view more button is visible
  @media (max-width: $break-point-lg) {
    @if config.$app_ctx != "schoolfeed-embed" {
      padding-bottom: $mobile-content-container-padding;
    }
  }
}

.content {
  transition: transform .5s ease;
}

.scroller {
  @include mixins.maxHeightTransition(1000px);

  .topButtons {
    padding: 1rem;
    text-align: center;

    button {
      font-weight: 600;
      background: var(--blocks);
      color: var(--blocksTextColor);
      padding: 6px 12px;
      border-radius: 5px;

      span {
        margin-right: 5px;
      }

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  .postManagerContainer {
    transition: max-height .25s ease, opacity .4s ease;
    opacity: 0;
    max-height: 0;
  }

  > p {
    padding: 1rem;
  }
}

.fetchMoreContainer {
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  z-index: 900;

  > button { // get more button
    background: var(--blocks);
    color: var(--blocksTextColor);
    padding: .5rem 1rem;
    border-radius: 10px;
  }
}

.postContainer {
  background: $clever-lgray;
  padding: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  overflow-x: hidden;

  > div:nth-of-type(2) { // organization select
    margin: 1rem 0;

    select {
      background: $clever-gray;
      color: black;
      border: none;
      padding: .25rem;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  input, .editor {
    color: $clever-dgray;
    background-color: inherit;
    border: 1px solid rgba(92, 93, 93, 0.17);
    border-radius: 5px;
    padding: .3rem;
  }

  > div:nth-of-type(1) { // textarea and item type buttons

    .imageContainer {
      position: relative;

      > div {
        > input, > .editor {
          margin-bottom: 1rem;
          width: 100%;
        }
      }

      > img:hover + button, > button:hover {
        display: block;
      }

      > img {
        $mediaWidth: 100px;
        $margin: 2rem;

        max-width: 100%;
        max-height: 100px;
        margin-bottom: 1rem;
        margin-left: 0;
        object-fit: contain;

        & ~ div {
          width: 100%;
        }

        @media(min-width: $width) {
          max-height: $mediaWidth;
          max-width: $mediaWidth;
          margin-bottom: 0;

          & ~ div { // content container when media is present
            width: calc(100% - #{$mediaWidth} - #{$margin}) !important;
            display: inline-block;
            margin-left: 0;
          }
        }
      }

      > button {
        display: none;
        position: absolute;
        top: 5px;
        left: 5px;
        background: $clever-dgray;
        padding: 5px;
        border-radius: 50%;
        text-align: center;

        span {
          color: white;
          font-size: 15px;
          width: 15px;
          height: 15px;
        }
      }
    }

    .buttons { // item type button container
      margin-bottom: 1rem;
      display: inline-block;

      div {
        display: inline-block;
        padding: 5px;
        min-width: 60px;
        text-align: center;

        &:not(:first-of-type) {
          margin-left: 15px;
        }
      }

      button {
        transition: opacity .25s ease;
      }

      button[disabled] {
        opacity: .5;
        cursor: default;
      }

      button, label {
        line-height: 2rem;
        font-weight: initial;
        vertical-align: top;
        cursor: pointer;
        margin: 0;
        display: inline-block;

        span {
          display: block;
          font-size: 2rem;
        }
      }

      input {
        opacity: 0;
        height: 0;
      }
    }
  }

  .submitButtonContainer {
    button {
      width: 100%;
      background: $clever-dgray;
      color: white;
      text-align: center;
      padding: .5rem;
      border-radius: 10px;
    }
  }

  .postAssignmentContainer {
    margin-bottom: 1rem;

    small {
      color: $error-red;
    }

    > span, > button {
      border-radius: 5px;
      padding: 2px 5px;
      margin: 2px;
    }
    > span {
      display: inline-block;
    }
    > span[data-is-request="false"] {
      background: $admin-blue-dark;
      color: white;
      border: 1px solid $admin-blue-dark;
    }
    > span[data-is-request="true"] {
      background: white;
      color: $admin-blue-dark;
      border: 1px solid $admin-blue-dark;

      .tooltip {
        @include mixins.tooltip();
      }
    }
    > button {
      background: lightgrey;
      color: black;
    }
  }
}

.submitButton {
  display: block;

  &Disabled {
    opacity: .5;
    pointer-events: none;
  }

  &:nth-of-type(2) {
    // delete button
    background: $error-red !important;
    margin-top: 1rem;
  }
}

.location {
  display: inline-block;
  padding: 0 .5rem;
  border-radius: 5px;
  margin-bottom: .5rem;
  width: 100%;
  background: white;
  border: 1px solid $clever-gray;

  span {
    width: 20px;
  }

  input {
    border: none;
    font-size: 1.5rem;
    height: auto;
    line-height: inherit;
    padding: .25rem;
    background: inherit;
    width: calc(100% - 20px);
  }
}

.dates {
  color: $clever-dgray;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;

  > div:last-of-type {
    margin-top: .5rem;
  }

  .reactDateTime {
    display: inline-block;

    input {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      text-underline-offset: 1px;
    }
  }

  .timePicker {
    padding: .3rem 0 .3rem .3rem;
    font-size: 1.2rem;

    input {
      text-decoration: underline;
      text-underline-offset: 1px;
    }
  }
}

.error {
  color: $error-red;
}

.fileUrl {
  margin: 0 0 1rem 0;

  img {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }

  span {
    color: $clever-dgray;
  }

  button {
    background: $clever-dgray;
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 100%;
    margin-left: 5px;

    span {
      color: white;
    }
  }
}

.organizationsContainer {
  @include mixins.translateTransition(translateX(100%), translateX(0));

  .organization {
    transition: transform .25s ease;
    box-shadow: $box-shadow;
    margin: .5rem 0;
    padding: .5rem 1rem;
    position: relative;
    background: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: transparentize($admin-blue-light, .99);
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .selectButton { // select
      transition: color .5s ease;
      color: $admin-blue-dark;
      font-size: 2rem;
      vertical-align: middle;
      text-align: left !important;

      span {
        color: $admin-blue-dark;
      }

      &[disabled] span {
        color: lightgrey;
        cursor: not-allowed;
      }

      &Selected {
        color: $admin-green-dark;

        span {
          color: $admin-green-dark;
        }
      }
    }

    button:last-of-type { // dig deeper
      height: 100%;
      width: 30px;
      font-size: 2rem;
      flex-grow: 1;
      text-align: right;
    }

    .backButton {
      width: 100%;

      button {
        text-align: left !important;
        width: 100% !important;
        font-size: 1.5rem !important;
      }
    }
  }
}

.organizationSelector {
  padding: 2.5% 5%;
  background: #F2F4F7;
  color: $admin-blue-dark;
  min-height: 278px; // Prevents container flashing when there are 0 items momentarily. Minimum height for district with
                     // two collections

  .selectedOrganization {
    background: $clever-gray;
    padding: .25rem .4rem;
    border-radius: 5px;
  }

  .acceptButton {
    background: white;
    color: $admin-blue-dark;
    border: 1px solid $admin-blue-dark;
    border-radius: 5px;
    padding: .2rem .4rem;
    margin-bottom: .5rem;
  }
}

.fetchMoreButton {
  margin-bottom: 1rem;
  button {
    background: $admin-blue-dark;
    color: white;
    border-radius: 10px;
    padding: .5rem 1rem;
  }
}

.multiSelectHeader {
  font-size: 2.5rem;
  color: $black;

  > div {
    font-size: 1rem;

    > span {
      background: $admin-blue-dark;
      color: white;
      padding: 5px;
      border-radius: 8px;
      margin-bottom: 5px;
      display: inline-block;
      font-size: 1.2rem;

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
  }

  button span {
    margin-left: 5px;
  }
}

.newsPostOption {
  display:flex;
  align-items:center;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  button label {
    text-decoration: underline;
    cursor: pointer;
  }

  label {
    margin: 0 0 0 10px;
  }

  .tooltip {
    @include mixins.tooltip();
  }
}

.required {
  border: 2px solid $red !important;
}

.loadingSpinner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.noPermissionText {
  font-size: 18px;
  text-align: center;
  width: 90%;
  font-weight: bold;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cantEditContent {
  opacity: 0.5;
  cursor: not-allowed;
  > div {
    pointer-events: none;
  }
}

.alertMessage {
  text-align: center;
  color: darkred;
  margin-bottom: 10px;
  .descriptionEditingInstructions {
    text-align: left;
    font-size: 1.2rem;
    color: $clever-dgray;
    margin-bottom: 1rem;
    > span {
      > button {
        text-decoration: underline;
      }
    }
  }
  hr {
    border: 2px solid var(--primaryNavBackground);
  }
}

.headerContainer {
  margin-top: 10px;
  margin-left: 2rem;
}

