@use "../../../../styles/variables.module" as *;
@mixin terraceDescription {
  * {
    strong {
      font-weight: $terrace-subheading-font-weight;
      @media(max-width: $break-point-sm) {
        font-size: $terrace-subheading-font-size-mobile;
      }
    }
    h3, h3 * {
      font-size: $terrace-subheading-font-size;
      @media(max-width: $break-point-sm) {
        font-size: $terrace-subheading-font-size-mobile;
      }
    }
    // default color for links to prevent ADA compliance issues
    a {
      color: #0000EE;
    }
    span, p, li, ul, ol, blockquote {
      font-size: $terrace-body-font-size;
      @media (max-width: $break-point-sm) {
        font-size: $terrace-body-font-size-mobile;
      }
    }
  }
}

@mixin callToActionButton {
  min-width: 90%;
  display: inline-block;
  padding: .45rem 1.25rem;
  border-radius: 5px;
  font-size: 1.2rem;
  letter-spacing: .15rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: $terrace-title-font-weight;
}
