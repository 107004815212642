@use "../../../styles/variables.module" as *;
@use "Modal.module";

.viewBtn {
  text-transform: uppercase;
  padding: 10px 50px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blocks);
  border: 2px solid var(--blocks);
  display: inline-block;
  cursor: pointer;

  &:hover {
    span {
      color: var(--darkerBlocks);
    }
  }

  &Facebook {
    border-color: $facebook-color;
    color: $facebook-color;

    &:hover {
      background-color: $facebook-color;
      color: white;
    }
  }

  &Twitter {
    border-color: $twitter-color;
    color: $twitter-color;

    &:hover {
      background-color: $twitter-color;
      color: white;
    }
  }
}

.modalBody {
  position: relative;
  padding: Modal.$modal-inner-padding 30px;

  header {
    padding-bottom: 20px;

    > div {
      vertical-align: middle;
    }
  }

  &Facebook {
    a {
      color: $facebook-color;
      font-weight: 600;
    }
  }

  &Twitter {
    a {
      color: $twitter-color;
      font-weight: 600;
    }
  }
}

.fontColorTwitter {
  color: $twitter-color;
}
.fontColorFacebook {
  color: $facebook-color;
}

.contentContainer {
  position: relative;
  word-break: break-word;
  overflow: hidden;
  border: 1px solid $lightgray;
  margin-bottom: 10px;

  &CourseWork {
    border: none;
  }

  > * {
    padding: 0 10px;
  }

  > div:first-of-type { // description block
    padding-bottom: 1rem;
  }

  > button {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 5px;
    padding: 4px 6px;
  }

  img {
    width: 100%;
  }

  &Thumbnail {
    width: 10rem;
    height: 7rem;
    line-height: 2rem;
    padding: 0.2rem;
    margin-right: 10px;
    margin-bottom: auto;
    border-radius: 4px;
    //border: 2px solid $theme-color-blue;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &ThumbnailVideo {
    width: 10rem;
    height: 7rem;
    line-height: 2rem;
    padding: 0.2rem;
    margin-right: 10px;
    margin-bottom: auto;
    border-radius: 4px;
    //border: 2px solid $theme-color-blue;
    position: relative;
    overflow: hidden;

    video {
      min-width: 100%;
      min-height: 100%;

      width: auto;
      height: 100%; // so the controls show up

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.imagePreview {
  width: 100%;
  max-height: 600px;
  object-fit: contain;
}

.thumbnail {
  display: inline-block;
  width: 15%;
  vertical-align: top;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
}

.titleSection {
  display: inline-block;
  width: 85%;
}

.thirdPartyBox {
  padding: 10px;

  h4 {
    word-wrap: break-word;
  }
}

.courseWorkMatlList {
  display: inline-block;
}

.courseWorkTitle {
  font-weight: bold;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}
